





























































import Vue from 'vue'
import { FirebaseAuth } from './firebase'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'App',

  data: () => ({
    drawer: false,
    group: null
  }),
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  methods: {
    logOut () {
      FirebaseAuth.signOut().then(() => {
        this.$router.push('/')
      })
    }
  }
})
