import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './assets/styles/index.css'
import Cloudinary from 'cloudinary-vue'
import vuetify from './plugins/vuetify'
import { FirebaseAuth } from './firebase'
import store from './store'
import VueObserveVisibility from 'vue-observe-visibility'

Vue.config.productionTip = false

FirebaseAuth.onAuthStateChanged(user => {
  store.dispatch('fetchUser', user)
  store.dispatch('fetchFamily', user)
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.use(Cloudinary, {
  configuration: { cloudName: 'da8mew2gy' }
})

Vue.use(VueObserveVisibility)
