import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'tablet',
    component: () => import('../views/Tablet.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('../views/auth/Signup.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/auth/Login.vue')
  },
  {
    path: '/joincall',
    name: 'joincall',
    component: () => import('../views/JoinCall.vue'),
    props: true
  }
]

const router = new VueRouter({
  routes
})

export default router
