import IUser from './IUser'
import Firebase, { FirebaseAuth, Firestore } from '../firebase'
import axios, { AxiosRequestConfig } from 'axios'
import LooseStringObject from './LooseStringObject'
import Family from '@/family/Family'
import IFamily from '@/family/IFamily'

export default class User implements IUser {
  readonly name: string
  readonly email: string
  readonly avatarUrl: string
  readonly uid: string
  readonly family: IFamily

  constructor (name: string, email: string, uid: string, family: IFamily, avatarUrl?: string) {
    this.name = name
    this.email = email
    this.avatarUrl = avatarUrl || this.GetDefaultUrl() // default avatar
    this.family = family
    this.uid = uid
  }

  GetDefaultUrl (): string {
    return 'https://res.cloudinary.com/da8mew2gy/image/upload/' + this.GetDefaultAvatarId() + '.png'
  }

  GetDefaultAvatarId (): string {
    return 'avatar-1577909_640_dxcyoz'
  }

  GetCurrentAuthUser (): Firebase.User | null {
    return FirebaseAuth.currentUser
  }

  async GetFamilyId (): Promise<string> {
    const db = Firestore
    const userCollection = db.collection('users')
    let familyId = ''
    try {
      const allUsersWithID = await userCollection.where('uid', '==', this.uid).get()
      if (allUsersWithID.size === 1) {
        allUsersWithID.forEach((doc) => {
          familyId = doc.data().familyId
        })
      } else {
        console.log('Received more or less than 1 user record')
      }
    } catch (error) {
      console.log('GetFamilyId got an error ' + error)
    }
    return familyId
  }

  UpdateUserAvatar (requestObj: AxiosRequestConfig) {
    this.UpdateCurrentUserProfile(this.name, requestObj)
  }

  UpdateUserName (newUserName: string) {
    this.UpdateCurrentUserProfile(newUserName)
  }

  UpdateCurrentUserProfile (newUserName: string, requestObj?: AxiosRequestConfig) {
    const currentUser = this.GetCurrentAuthUser()

    if (!currentUser || !newUserName) {
      return
    }

    if (requestObj) {
      axios(requestObj)
        .then(response => {
          const results = response.data
          console.log('public_id', results.public_id)
          this.UpdateUserProfile(currentUser, newUserName, results.public_id)
        })
    } else {
      this.UpdateUserProfile(currentUser, newUserName)
    }
  }

  private UpdateUserProfile (user: Firebase.User, newUserName: string, publicId?: string) {
    const profileObject: LooseStringObject = {
      displayName: newUserName // use current name if no new name given
    }

    if (publicId) {
      profileObject.photoURL = 'https://res.cloudinary.com/da8mew2gy/image/upload/' + publicId + '.png'
    }

    user.updateProfile(profileObject)
      .then(function () {
        console.log('Profile Update successful')
        location.reload()
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  CreateUser (user: User, familyId: string) {
    const family = new Family(familyId)
    if (family.id === null) {
      console.log('FamilyId is null')
      return
    }

    const db = Firestore
    const userCollection = db.collection('users')
    userCollection
      .add({
        name: user.name,
        email: user.email,
        avatarURL: user.avatarUrl,
        uid: user.uid,
        familyId: familyId
      })
      .then(() => {
        console.log('User successfully created!')
      })
      .catch((error) => {
        console.error('Error creating document: ', error)
      })

    family.CreateMembership(user)
  }
}
